.ublog-post-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22em, 1fr));
  grid-gap: 1em;
}
.ublog-post-card {
  @extend %box-neat-force;

  @include transition(box-shadow);

  background: $c-bg-zebra;
  color: $c-font;
  position: relative;
  direction: ltr;
  text-align: left;

  &--link:hover {
    box-shadow:
      0 0 5px $c-link,
      0 0 20px $c-link;
  }

  &__top {
    display: block;
    position: relative;
    line-height: 0;
  }

  &__over-image {
    position: absolute;
    top: 0;
    line-height: 1.3em;
    &.pos-bottom {
      top: unset;
      bottom: 0;
    }
    @include padding-direction(0.2em, 0.5em, 0.3em, 0.5em);
    --c-ublog-post-card-over-image: #ddd;
    @include if-light {
      --c-ublog-post-card-over-image: #333;
    }

    background: rgba(0, 0, 0, 0.65);
    color: --c-ublog-post-card-over-image;
    text-shadow: 0 1px 1px black;

    @include if-light {
      background: rgba(255, 255, 255, 0.65);
      text-shadow: 0 1px 1px white;
    }
    opacity: 1;
  }
  time {
    @include inline-start(0);
    border-radius: 0 0 $box-radius-size 0;
  }
  .user-link {
    @include inline-end(0);
    border-radius: 0 0 0 $box-radius-size;
  }

  &__image {
    width: 100%;
    height: auto;
    &.ublog-post-image-default {
      background-image: img-url('placeholder-margin.png');
      background-size: cover;
      background-position: center;
    }
    @include broken-img(16 / 10);
  }

  &__content {
    padding: 4% 3% 4% 6%;
    display: block;
    max-height: 12em;
  }

  &__title {
    @include line-clamp(2);
    font-size: 1.3em;
    color: $c-font-clear;
  }

  &__intro {
    @extend %roboto, %break-word;
    @include line-clamp(2);

    color: $c-font;
    margin-top: 0.5em;
  }

  &--by-lichess {
    border: 1px solid $c-brag;
    .user-link {
      color: $c-brag;
      font-weight: bold;
    }
  }
}
